.main {
  height: 600px;
}

img {
  width: 100%;
  display: block;
  border-radius: var(--radius);
  object-fit: contain;
}

.product-gallery {
  margin-top: 1rem;
  display: flex;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1rem;
  overflow-x: scroll;
}

.product-gallery img {
  height: 3rem;
  cursor: pointer;
}

.active {
  box-shadow: 0px 0px 0px 2px var(--clr-primary-5);
}

@media (max-width: 576px) {
  .main {
    height: 300px;
  }
  .product-gallery img {
      height: 3rem;
      width: auto;
    }
}
@media (min-width: 992px) {
  .main {
    height: 500px;
  }
  .product-gallery img {
      height: 3rem;
      width: auto;
    }
}